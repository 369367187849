import React, { ReactNode } from "react";
import Link from "next/link";

interface Props {
  href: string;
  children: ReactNode;
}

const InternalLink: React.FC<Props> = ({ href, children, ...props }) => {
  const validateHref = (url: string) => {
    if (url && !url.includes("http") && url.split("")[0] !== "/") {
      return "/" + url;
    }
    return url || "";
  };
  return (
    <Link href={validateHref(href)} {...props}>
      {children}
    </Link>
  );
};

export default InternalLink;
